import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Resolve, RouterStateSnapshot } from "@angular/router";
import { Observable, Subject, first } from "rxjs";
import { Permissions } from "src/shared/classes/permissions";
import { SessionService } from "src/shared/services/session/session.service";

@Injectable()
export class UserGuard implements CanActivate, Resolve<any> {
  resolve$ = new Subject();

  constructor(
    private _serviceSession: SessionService,
    private _permissions: Permissions,
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.assertResolve();

    return this.resolve$;
  }

  assertResolve() {
    this._serviceSession.getResolve
    .pipe(
      first()
    )
    .subscribe((response: any) => {
      this.resolve$.next(response);
    });
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    this._permissions.canActivate(route, this._serviceSession.getRole);
    
    return this._permissions.canActivate$;
  }
}