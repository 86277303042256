import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, first } from 'rxjs';
import { environment } from 'src/environments/environment.development';
import { AlertsService } from '../alerts/alerts.service';

@Injectable({
  providedIn: 'root'
})
export class ItemService {
  private _subCategory = '';
  private _page: number = 1;
  private _body: any;

  constructor(
    private _httpClient: HttpClient,
    private _serviceAlerts: AlertsService,
  ) {}

  getAppItemByCategory(category: any, size: any = 10, ...params: any[]) {
    this.setDefaultValuesToVariables();
    this.assignValuesToVariables(params);

    return this._httpClient.get(`${ environment.BACKEND_DOMAIN }/shared/app-item/by/category?category=${ category }&count=${ size }&sub_category=${ this._subCategory }&page=${ this._page }`);
  }

  private setDefaultValuesToVariables() {
    this._subCategory = '';
    this._page = 1;
  }

  private assignValuesToVariables(params: any[]) {
    if (params[0]) {
      this._subCategory = params[0];
    }

    if (params[1]) {
      this._page = params[1];
    }
  }

  get getGenres() {
    return this._httpClient.get(`${ environment.BACKEND_DOMAIN }/shared/app-item/genres`);
  }

  get getActivationRegion() {
    return this._httpClient.get(`${ environment.BACKEND_DOMAIN }/shared/app-item/activation-region`);
  }

  get getPublishers() {
    return this._httpClient.get(`${ environment.BACKEND_DOMAIN }/shared/app-item/publishers`);
  }

  get getDevelopers() {
    return this._httpClient.get(`${ environment.BACKEND_DOMAIN }/shared/app-item/developers`);
  }

  get getPlatforms() {
    return this._httpClient.get(`${ environment.BACKEND_DOMAIN }/shared/app-item/platforms`);
  }

  sortAppItem(size: number = 10, value: any) {
    this._body = { value };

    return this._httpClient.post(`${ environment.BACKEND_DOMAIN }/shared/app-item/sort?count=${ size }`, this._body);
  }

  getAppItemEditionsByLatinName(latinName: string) {
    return this._httpClient.get(`${ environment.BACKEND_DOMAIN }/shared/app-item/editions?latin_name=${ latinName }`);
  }

  getAppItemByLatinName(latinName: string) {
    return this._httpClient.get(`${ environment.BACKEND_DOMAIN }/shared/app-item?latin_name=${ latinName }`);
  }

  inStockByID(id: any) {
    return this._httpClient.get(`${ environment.BACKEND_DOMAIN }/shared/app-item/in-stock?id=${ id }`);
  }

  inStockByLatinName(latinName: any) {
    return this._httpClient.get(`${ environment.BACKEND_DOMAIN }/shared/app-item/in-stock/latin-name?latinName=${ latinName }`);
  }

  getAppItemByListLatinName(listLatinName: any[]) {
    return this._httpClient.get(`${ environment.BACKEND_DOMAIN }/shared/app-item/list?latin_name=${ listLatinName }`);
  }

  getAppItemByID(id: any) {
    return this._httpClient.get(`${ environment.BACKEND_DOMAIN }/shared/app-item?id=${ id }`);
  }

  refreshInfo() {
    this._httpClient.get(`${ environment.BACKEND_DOMAIN }/shared/app-item/refresh-info`)
    .pipe(
      first()
    )
    .subscribe(() => {
      this._serviceAlerts.onRefreshInfo();
    });
  }

  isIndividualLatinName(value: any) {
    return this._httpClient.get(`${ environment.BACKEND_DOMAIN }/shared/app-item/is-individual-latin-name?latin_name=${ value }`);
  }

  addAppItem(value: any) {
    this._body = { value };

    return this._httpClient.post(`${ environment.BACKEND_DOMAIN }/shared/app-item/add`, this._body);
  }

  setInStock(latinName: any, value: boolean) {
    this._body = { latinName, value };

    return this._httpClient.post(`${ environment.BACKEND_DOMAIN }/shared/app-item/in-stock`, this._body);
  }

  get getAllAppItemWholesale() {
    return this._httpClient.get(`${ environment.BACKEND_DOMAIN }/shared/app-item/wholesale`);
  }

  get getAllAppItem() {
    return this._httpClient.get(`${ environment.BACKEND_DOMAIN }/shared/app-item/all`);
  }

  getSimilarAppItem(genreList: any, filter: string) {
    this._body = { genreList, filter };

    return this._httpClient.post(`${ environment.BACKEND_DOMAIN }/shared/app-item/similar`, this._body);
  }
}
