import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Output } from '@angular/core';
import { Actions } from './actions';
import { ActionsService } from './actions.service';
import { finalize, first, takeUntil } from 'rxjs';
import { SessionService } from 'src/shared/services/session/session.service';
import { CookiesService } from 'src/shared/services/cookies/cookies.service';

@Component({
  selector: 'app-actions',
  templateUrl: './actions.component.html',
  styleUrls: ['./actions.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionsComponent extends Actions {
  @Output() isLoad = new EventEmitter();

  constructor(
    private _service: ActionsService,
    private _serviceSession: SessionService,
    private _serviceCookies: CookiesService,
    changeDetector: ChangeDetectorRef,
  ) {
    super(_service, _serviceSession, _serviceCookies, changeDetector);

    _service.session$
    .pipe(
      takeUntil(this.unsubscribe$)
    )
    .subscribe(() => {
      this.serviceSession.getID
      .pipe(
        first()
      )
      .subscribe((response: any) => {
        this.sessionID = response?.value;
  
        this.changeDetector.detectChanges();
      });
    });
  }

  ngOnInit() {
    this.setItems = this._service.getItems;

    this.serviceSession.getID
    .pipe(
      first()
    )
    .subscribe((response: any) => {
      this.sessionID = response?.value;

      this.changeDetector.detectChanges();
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  set setItems(items: any) {
    items
    .pipe(
      first(),
      finalize(() => {
        this.setLoad = true;
      })
    )
    .subscribe((response: any) => {
      this.items = response.data;

      this.changeDetector.detectChanges();
    });
  }

  private set setLoad(value: boolean) {
    this.isLoad.emit(value);
  }
}
