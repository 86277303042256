import { Subject, first } from "rxjs";

const PERMISSIONS_LIST: any = {
  '': null,
  'documents/:document': null,
  'help': null,
  'catalog/:category': null,
  'catalog/:category/:sub-category': null,
  'app/:item': null,
  'cart': null,
  'payment-state/:state': null,
  'auth': 'not-logged-in',
  'profiles/:id': null,
  'invoice/:id': 'logged-in',
  'wholesale': 'wholesale',
  'info': null,
};

export class Permissions {
  canActivate$ = new Subject<boolean>();

  canActivate(route: any, observableRole: any) {
    observableRole
    .pipe(
      first()
    )
    .subscribe((response: any) => {
      const path = route?.routeConfig?.path;
      const role = response?.value;

      this.setCanActivate(path, role);
    });
  }

  setCanActivate(path: any, role: any) {
    if (PERMISSIONS_LIST[path] === null) {
      if (role !== 'banned') {
        this.canActivate$.next(true);
      } else {
        this.canActivate$.next(false);
      }
    } else {
      if (PERMISSIONS_LIST[path] === 'not-logged-in') {
        if (role) {
          this.canActivate$.next(false);
        } else {
          this.canActivate$.next(true);
        }
      } else if (PERMISSIONS_LIST[path] === 'logged-in') {
        if (role) {
          this.canActivate$.next(true);
        } else {
          this.canActivate$.next(false);
        }
      } else if (PERMISSIONS_LIST[path] === 'wholesale') {
        if (role === 'wholesale-buyer' || role === 'admin') {
          this.canActivate$.next(true);
        } else {
          this.canActivate$.next(false);
        }
      } else {
        this.canActivate$.next(false);
      }
    }
  }
}
