import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Menu } from './menu';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuComponent extends Menu {
  ngAfterViewInit() {
    this.setMenu = <HTMLElement>document.getElementById('menu');

    document.addEventListener('scroll', (event) => {
      this.hideOrShowMenuByCondition();
    });
  }
}