import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.development';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  private _body: any;

  constructor(
    private _httpClient: HttpClient,
  ) {}

  createSession(value: any) {
    this._body = { value };

    return this._httpClient.post(`${ environment.BACKEND_DOMAIN }/shared/session/create`, this._body, { withCredentials: true });
  }

  get getSession() {
    return this._httpClient.get(`${ environment.BACKEND_DOMAIN }/shared/session/get`, { withCredentials: true });
  }

  get getRole() {
    return this._httpClient.get(`${ environment.BACKEND_DOMAIN }/shared/session/role`, { withCredentials: true });
  }

  get getID() {
    return this._httpClient.get(`${ environment.BACKEND_DOMAIN }/shared/session/id`, { withCredentials: true });
  }

  destroySession() {
    return this._httpClient.get(`${ environment.BACKEND_DOMAIN }/shared/session/destroy`, { withCredentials: true });
  }

  get getResolve() {
    return this._httpClient.get(`${ environment.BACKEND_DOMAIN }/shared/session/resolve`, { withCredentials: true });
  }
}
