import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { CartService } from 'src/pages/cart/cart.service';

type Cookies = 'cart' | 'favorites' | 'currentPage';

@Injectable({
  providedIn: 'root'
})
export class CookiesService {
  constructor(
    private _serviceCookie: CookieService,
    private _serviceCart: CartService,
  ) {}

  add(where: Cookies, what: any) {
    if (this.check(where) && this._serviceCookie.get(where) !== '') {
      let cookie = this._serviceCookie.get(where);
      cookie += ',' + what;
  
      this.refresh(where, cookie);
    } else {
      let cookie = what;

      this.refresh(where, cookie);
    }
  }

  refresh(where: Cookies, cookie: any) {
    this._serviceCookie.delete(where);
    this._serviceCookie.set(where, cookie, { expires: 365, path: '/' });

    this.refreshDependencies();
  }

  delete(where: Cookies) {
    this._serviceCookie.delete(where);
  }

  refreshDependencies() {
    this._serviceCart.refreshCart$.next(null);
    this._serviceCart.refreshFavorites$.next(null);
  }

  getRandomInteger(min: number, max: number) {
    let random = min + Math.random() * (max + 1 - min);

    return Math.floor(random);
  }

  containedList(where: Cookies, what: any) {
    return this.getList(where)?.includes(what?.toString());
  }

  getList(where: Cookies) {
    let cookie = this._serviceCookie.get(where);
    let cookieList = cookie?.split(',');
    cookieList = cookieList?.filter(item => item !== '');

    return cookieList;
  }

  get(where: Cookies) {
    return this._serviceCookie.get(where);
  }

  check(where: Cookies) {
    return this._serviceCookie.check(where);
  }

  amount(where: Cookies, what: any) {
    let amount = 0;
    this.getList(where)?.forEach((item: any) => {
      if (item?.toString() === what?.toString()) {
        ++amount;
      }
    });

    return amount;
  }

  reduce(where: Cookies, what: any) {
    const index = this.getList(where)?.indexOf(what?.toString());
    
    if (index !== -1) {
      let cookie: any = this.getList(where);
      cookie?.splice(index, 1);

      this.refresh(where, cookie);
    }
  }

  push(where: Cookies, index: any, item: any) {
    let cookie = `${ index }|${ item }`;

    this.replace(where, cookie);
  }

  replace(where: Cookies, what: any) {
    let cookie: string[] = [];

    const whatArray = what?.split('|');
    const whatLink = whatArray[0];
    const whatPage = whatArray[1];
    
    if (this.check(where)) {
      let match = false;
      this.getList(where)?.forEach((item: any) => {
        const array = item?.split('|');
        const link = array[0];
  
        if (link === whatLink) {
          cookie?.push(`${ whatLink }|${ whatPage }`);

          match = true;
        } else {
          cookie?.push(array?.join('|'));
        }
      });

      if (!match) {
        cookie?.push(`${ whatLink }|${ whatPage }`);
      }
    } else {
      cookie?.push(`${ whatLink }|${ whatPage }`);
    }

    this.refresh(where, cookie);
  }
}
