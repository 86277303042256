import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment.development';

@Injectable({
  providedIn: 'root'
})
export class ActionsService {
  session$ = new Subject();

  constructor(private _http: HttpClient) {}

  get getItems() {
    return this._http.get(`${ environment.BACKEND_DOMAIN }/app/menu/actions/items`);
  }

  refreshSession() {
    this.session$.next(null);
  }
}
