import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.development';

@Injectable({
  providedIn: 'root'
})
export class FooterService {
  constructor(private _http: HttpClient) {}

  get items() {
    return this._http.get(`${ environment.BACKEND_DOMAIN }/app/footer/items`);
  }
}
