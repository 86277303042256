export abstract class Menu {
  openSidebar = false;
  menu: any;
  isLoad: boolean = false;
  private _scrollPrev = 0;

  toggleSidebar(open: boolean) {
    this.openSidebar = open;
  }

  set setMenu(value: any) {
    this.menu = value;
  }

  hideOrShowMenuByCondition() {
    let scrolled = window.scrollY;

    if (scrolled > 50 && scrolled > this._scrollPrev) {
      this.menu.classList.add("_hide");
    } else {
      this.menu.classList.remove("_hide");
    }

    this._scrollPrev = scrolled;
  }
}
