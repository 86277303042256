<ng-container *ngIf="type === 'footer__links'">
  <a class="aui-link__secondary links__item">Соглашение</a>
  <a class="aui-link__secondary links__item">Соглашение об обработке персональных данных</a>
  <a class="aui-link__secondary links__item">Политика Конфиденциальности</a>
  <a class="aui-link__secondary links__item">Политика Cookie</a>
  <a class="aui-link__secondary links__item">Обратная Связь</a>
</ng-container>
<ng-container *ngIf="type === 'footer__actions'">
  <a class="aui-link__secondary actions__item">
    <app-svg class="actions__image"
      [src]="'auiSvgVk'"
    ></app-svg>
  </a>
  <a class="aui-link__secondary actions__item">
    <app-svg class="actions__image"
      [src]="'auiSvgTme'"
    ></app-svg>
  </a>
  <a class="aui-link__secondary actions__item">
    <app-svg class="actions__image"
      [src]="'auiSvgYoutube'"
    ></app-svg>
  </a>
</ng-container>
<ng-container *ngIf="type === 'footer__copyright'">
  <p class="aui-text__body-xs copyright__text">© 2024 ATLANT STORE. Все торговые марки являются собственностью соответствующих владельцев.</p>
</ng-container>
<ng-container *ngIf="type === 'footer__payment-methods'">
  <app-svg class="payment-methods__image" [src]="'auiSvgMir'"></app-svg>
  <app-svg class="payment-methods__image" [src]="'auiSvgVisa'"></app-svg>
  <app-svg class="payment-methods__image" [src]="'auiSvgMastercard'"></app-svg>
  <app-svg class="payment-methods__image" [src]="'auiSvgUnionPay'"></app-svg>
  <app-svg class="payment-methods__image" [src]="'auiSvgSBP'"></app-svg>
  <app-svg class="payment-methods__image" [src]="'auiSvgYandexPay'"></app-svg>
  <app-svg class="payment-methods__image" [src]="'auiSvgMtsPay'"></app-svg>
  <app-svg class="payment-methods__image" [src]="'auiSvgMirPay'"></app-svg>
  <app-svg class="payment-methods__image" [src]="'auiSvgSberPay'"></app-svg>
</ng-container>