import { Subject, first } from "rxjs";

export abstract class Actions {
  unsubscribe$: any = new Subject();
  sessionID: any;
  items: any = [];

  constructor(
    public service: any,
    public serviceSession: any,
    public serviceCookies: any,
    public changeDetector: any,
  ) {}

  exit() {
    this.serviceSession.destroySession()
    .pipe(
      first()
    )
    .subscribe(() => {
      this.service.refreshSession();
    });
  }
}
