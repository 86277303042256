import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { Footer } from './footer';
import { FooterService } from './footer.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent extends Footer {
  constructor(private _service: FooterService, changeDetector: ChangeDetectorRef) {
    super(changeDetector);
  }

  ngOnInit() {
    this.setItems = this._service.items;
  }
}
