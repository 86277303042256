import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SvgComponent } from './svg.component';



@NgModule({
  bootstrap: [
    SvgComponent,
  ],
  declarations: [
    SvgComponent,
  ],
  imports: [
    CommonModule
  ],
  exports: [
    SvgComponent,
  ],
})
export class SvgModule { }
