import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-cls',
  templateUrl: './cls.component.html',
  styleUrls: ['./cls.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClsComponent {
  @Input() type: any;
}
