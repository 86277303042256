<tui-loader class="menu__loader"
  [overlay]="true"
  *ngIf="!isLoad"
>
  <nav class="menu">
    <div class="menu__group">
      <h1 class="aui-text__logo menu__logo"
        routerLink="/"
      >ATLANT STORE</h1>
      <small class="menu__sign"><strong>КЛЮЧИ И КОДЫ ПОПОЛНЕНИЯ</strong></small>
    </div>
    <div class="menu__actions">
      <app-actions appActions media="dekstop"
        (isLoad)="isLoad = $event"
      ></app-actions>
      <a class="aui-text__body-m aui-link__primary actions__mobile"
        (click)="toggleSidebar(true)"
        (tuiActiveZoneChange)="toggleSidebar($event)"
      >
        <tui-svg src="tuiIconMenuLarge"></tui-svg>
        <section class="actions__sidebar"
          *tuiSidebar="openSidebar; direction: 'right'"
        >
          <app-actions appActions media="mobile"></app-actions>
        </section>
      </a>
    </div>
  </nav>
</tui-loader>
<nav class="menu"
  *ngIf="isLoad"
>
  <div class="menu__group">
    <h1 class="aui-text__logo menu__logo"
      routerLink="/"
    >ATLANT STORE</h1>
    <small class="menu__sign"><strong>КЛЮЧИ И КОДЫ ПОПОЛНЕНИЯ</strong></small>
  </div>
  <div class="menu__actions">
    <app-actions appActions media="dekstop"
      (isLoad)="isLoad = $event"
    ></app-actions>
    <a class="aui-text__body-m aui-link__primary actions__mobile"
      (click)="toggleSidebar(true)"
      (tuiActiveZoneChange)="toggleSidebar($event)"
    >
      <tui-svg src="tuiIconMenuLarge"></tui-svg>
      <section class="actions__sidebar"
        *tuiSidebar="openSidebar; direction: 'right'"
      >
        <app-actions appActions media="mobile"></app-actions>
      </section>
    </a>
  </div>
</nav>