import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UserGuard } from './../shared/guards/user/user.guard';
import { Permissions } from 'src/shared/classes/permissions';

const routes: Routes = [
  {
    path: '',
    redirectTo: '',
    pathMatch: 'full',
  },
  {
    path: '',
    loadChildren: () =>
      import('./../pages/home/home.module').then(
        (m) => m.HomeModule
      ),
    canActivate: [UserGuard],
    resolve: [UserGuard],
  },
  {
    path: 'documents/:document',
    loadChildren: () =>
      import('./../pages/documents/documents.module').then(
        (m) => m.DocumentsModule
      ),
    canActivate: [UserGuard],
    resolve: [UserGuard],
  },
  {
    path: 'help',
    loadChildren: () =>
      import('./../pages/help/help.module').then(
        (m) => m.HelpModule
      ),
    canActivate: [UserGuard],
    resolve: [UserGuard],
  },
  {
    path: 'catalog/:category',
    loadChildren: () =>
      import('./../pages/catalog/catalog.module').then(
        (m) => m.CatalogModule
      ),
    canActivate: [UserGuard],
    resolve: [UserGuard],
  },
  {
    path: 'catalog/:category/:sub-category',
    loadChildren: () =>
      import('./../pages/catalog/catalog.module').then(
        (m) => m.CatalogModule
      ),
    canActivate: [UserGuard],
    resolve: [UserGuard],
  },
  {
    path: 'app/:item',
    loadChildren: () =>
      import('./../pages/app/app.module').then(
        (m) => m.AppModule
      ),
    canActivate: [UserGuard],
    resolve: [UserGuard],
  },
  {
    path: 'cart',
    loadChildren: () =>
      import('./../pages/cart/cart.module').then(
        (m) => m.CartModule
      ),
    canActivate: [UserGuard],
    resolve: [UserGuard],
  },
  {
    path: 'payment-state/:state',
    loadChildren: () =>
      import('./../pages/payment-state/payment-state.module').then(
        (m) => m.PaymentStateModule
      ),
    canActivate: [UserGuard],
    resolve: [UserGuard],
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('./../pages/auth/auth.module').then(
        (m) => m.AuthModule,
      ),
    canActivate: [UserGuard],
    resolve: [UserGuard],
  },
  {
    path: 'profiles/:id',
    loadChildren: () =>
      import('./../pages/profiles/profiles.module').then(
        (m) => m.ProfilesModule,
      ),
    canActivate: [UserGuard],
    resolve: [UserGuard],
  },
  {
    path: 'invoice/:id',
    loadChildren: () =>
      import('./../pages/invoice/invoice.module').then(
        (m) => m.InvoiceModule,
      ),
    canActivate: [UserGuard],
    resolve: [UserGuard],
  },
  {
    path: 'wholesale',
    loadChildren: () =>
      import('./../pages/wholesale/wholesale.module').then(
        (m) => m.WholesaleModule,
      ),
    canActivate: [UserGuard],
    resolve: [UserGuard],
  },
  {
    path: 'info',
    loadChildren: () =>
      import('./../pages/info/info.module').then(
        (m) => m.InfoModule,
      ),
    canActivate: [UserGuard],
    resolve: [UserGuard],
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  providers: [UserGuard, Permissions],
  exports: [RouterModule],
})
export class AppRoutingModule {}
