<section class="footer">
  <div class="footer__links">
    <app-cls [type]="'footer__links'"
      *ngIf="!items.links"
    ></app-cls>
    <a class="aui-link__secondary links__item" 
      *ngFor="let item of items.links"
      [routerLink]="item?.link"
    >
      {{ item?.name }}
    </a>
  </div>
  <div class="footer__actions">
    <app-cls [type]="'footer__actions'"
      *ngIf="!items.actions"
    ></app-cls>
    <a class="aui-link__secondary actions__item"
      *ngFor="let item of items.actions"
      [href]="item?.href"
      target="_blank"
    >
      <app-svg class="actions__image"
        *ngIf="item?.svg"
        [src]="item?.svg"
      ></app-svg>
      <img class="actions__image"
        *ngIf="item?.image"
        [src]="item?.image"
        decoding="async"
      />
    </a>
  </div>
  <div class="footer__copyright">
    <app-cls [type]="'footer__copyright'"
      *ngIf="!items.copyright"
    ></app-cls>
    <p class="aui-text__body-xs copyright__text">{{ items?.copyright }}</p>
  </div>
  <div class="footer__payment-methods">
    <app-cls [type]="'footer__payment-methods'"
      *ngIf="!items['payment-methods']"
    ></app-cls>
    <ng-container *ngFor="let item of items['payment-methods']">
      <app-svg class="payment-methods__image"
        *ngIf="item?.svg"
        [src]="item?.svg"
      ></app-svg>
      <img class="payment-methods__image"
        *ngIf="item?.image"
        [src]="item?.image"
        decoding="async"
      />
    </ng-container>
  </div>
</section>