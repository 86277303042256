import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment.development';
import { ItemService } from 'src/shared/services/app-item/item.service';

@Injectable({
  providedIn: 'root'
})
export class CartService {
  refreshCart$ = new Subject();
  refreshFavorites$ = new Subject();
  private _body: any;

  constructor(
    private _http: HttpClient,
    private _serviceAppItem: ItemService,
  ) {}

  getInfoByLatinName(latinName: string) {
    return this._serviceAppItem.getAppItemByLatinName(latinName);
  }

  getInfoByListLatinName(listLatinName: any[]) {
    return this._serviceAppItem.getAppItemByListLatinName(listLatinName);
  }

  addInvoice(id: number, products: any[], total: any, state: any = 'unpaid', email: any = null) {
    this._body = { id, products, sum: total, state, email };

    this._http.post(`${ environment.BACKEND_DOMAIN }/pages/cart/add-invoice`, this._body).subscribe(() => {});
  }
}
