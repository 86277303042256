import { Inject, Injectable } from '@angular/core';
import { TuiAlertService } from '@taiga-ui/core';
import { TuiPushService } from '@taiga-ui/kit';
import { take } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AlertsService {
  constructor(
  @Inject(TuiAlertService) private readonly alerts: TuiAlertService,
  ) {}

  showAddedToCart(name: any) {
    this.alerts
    .open(`Игра ${ name } - добавлена в корзину!`,{
       label: 'Корзина',
       status: 'success',
       icon: 'tuiIconShoppingCart',
    })
    .subscribe();
  }

  showAddedToFavorites(name: any) {
    this.alerts
    .open(`Игра ${ name } - добавлена в список избранных!`,{
       label: 'Избранное',
       status: 'info',
       icon: 'tuiIconHeart',
    })
    .subscribe();
  }

  showRemoveFromFavorites(name: any) {
    this.alerts
    .open(`Игра ${ name } - удалена из списка избранныx.`,{
      label: 'Избранное',
      status: 'error',
      icon: 'tuiIconHeart',
    })
    .subscribe();
  }

  onFailedLogin() {
    this.alerts
    .open(`Неверная почта (никнейм) или пароль.`,{
      label: 'Ошибка',
      status: 'error',
    })
    .subscribe();
  }

  onRegister(email: any) {
    this.alerts
    .open(`Аккаунт на почту - ${ email } создан!`,{
      label: 'Аккаунт создан',
      status: 'success',
    })
    .subscribe();
  }

  onFailedRegister(email: any) {
    this.alerts
    .open(`Почта - ${ email } уже занята. По всем вопросам обращайтесь в поддержку сайта!`,{
      label: 'Почта занята',
      status: 'warning',
    })
    .subscribe();
  }

  onRepeatCode() {
    this.alerts
    .open('Код повторно отправлен на Вашу почту!',{
      label: 'Проверьте почту',
      status: 'info',
      icon: 'tuiIconMailLarge',
    })
    .subscribe();
  }

  onSendCode() {
    this.alerts
    .open('Код отправлен на Вашу почту!',{
      label: 'Завершите подтверждение почты',
      status: 'info',
      icon: 'tuiIconMailLarge',
    })
    .subscribe();
  }

  onCode() {
    this.alerts
    .open(`Почта успешно подтверждена!`,{
      label: 'Добро пожаловать',
      status: 'success',
      icon: 'tuiIconMailLarge',
    })
    .subscribe();
  }

  onFailedCode() {
    this.alerts
    .open(`Отправленный код не совпадает с введенным. Проверьте почту.`,{
      label: 'Неверный код',
      status: 'error',
    })
    .subscribe();
  }

  onFile() {
    this.alerts
    .open(`Файл успешно загружен!`,{
      label: 'Успешно',
      status: 'success',
      icon: 'tuiIconUploadLarge',
    })
    .subscribe();
  }

  onFailedFile() {
    this.alerts
    .open(`Убедитесь, что файл соответствует заданным параметрам.`,{
      label: 'Файл слишком большой',
      status: 'error',
      icon: 'tuiIconUploadLarge',
    })
    .subscribe();
  }

  onChangeInfo() {
    this.alerts
    .open(`Изменения успешно сохранены!`,{
      label: 'Успешно',
      status: 'success',
      icon: 'tuiIconEdit3Large',
    })
    .subscribe();
  }

  onFailedChangeInfo() {
    this.alerts
    .open(`Никнейм уже занят. Остальные изменения успешно сохранены!`,{
      label: 'Внимание',
      status: 'warning',
      icon: 'tuiIconEdit3Large',
    })
    .subscribe();
  }

  onChangePassword() {
    this.alerts
    .open(`Пароль успешно изменен!`,{
      label: 'Успешно',
      status: 'success',
      icon: 'tuiIconMinusCircleLarge',
    })
    .subscribe();
  }

  onFailedChangePassword() {
    this.alerts
    .open(`Неверный пароль.`,{
      label: 'Ошибка',
      status: 'error',
      icon: 'tuiIconMinusCircleLarge',
    })
    .subscribe();
  }

  onForgotPassword() {
    this.alerts
    .open(`Новый пароль отправлен на почту!`,{
      label: 'Проверьте почту',
      status: 'success',
    })
    .subscribe();
  }

  onFailedForgotPassword() {
    this.alerts
    .open(`Такой почты не существует.`,{
      label: 'Ошибка',
      status: 'error',
    })
    .subscribe();
  }

  onComment() {
    this.alerts
    .open(`Ваш комментарий оставлен!`,{
      label: 'Успешно',
      status: 'success',
      icon: 'tuiIconMessageCircleLarge',
    })
    .subscribe();
  }
  
  onCommentEdit() {
    this.alerts
    .open(`Ваш комментарий изменен!`,{
      label: 'Успешно',
      status: 'success',
      icon: 'tuiIconEdit2Large',
    })
    .subscribe();
  }

  onCommentRemove() {
    this.alerts
    .open(`Комментарий удален!`,{
      label: 'Успешно',
      status: 'success',
      icon: 'tuiIconTrash2Large',
    })
    .subscribe();
  }

  onAnswer() {
    this.alerts
    .open(`Ваш ответ отправлен!`,{
      label: 'Успешно',
      status: 'success',
      icon: 'tuiIconMessageCircleLarge',
    })
    .subscribe();
  }

  tooManyRequests() {
    this.alerts
    .open(`Слишком много запросов! Подождите некоторое время...`,{
      label: 'Слишком много запросов',
      status: 'warning',
      icon: 'tuiIconClockLarge',
    })
    .subscribe();
  }

  onRefreshInfo() {
    this.alerts
    .open(`Вся информация обновлена!`,{
      label: 'Успешно',
      status: 'success',
      icon: 'tuiIconInfoLarge',
    })
    .subscribe();
  }

  onFailedName() {
    this.alerts
    .open(`Такое имя уже занято! Имя должно быть уникальным.`,{
      label: 'Такое имя уже занято',
      status: 'error',
    })
    .subscribe();
  }

  onAddAppItem() {
    this.alerts
    .open(`Товар успешно добавлен!`,{
      label: 'Успешно',
      status: 'success',
    })
    .subscribe();
  }

  onFeedback() {
    this.alerts
    .open(`Ваш отзыв оставлен!`,{
      label: 'Успешно',
      status: 'success',
      icon: 'tuiIconMessageCircleLarge',
    })
    .subscribe();
  }

  onFailedFeedback() {
    this.alerts
    .open(`Вы уже оставляли отзыв.`,{
      label: 'Ошибка',
      status: 'error',
      icon: 'tuiIconMessageCircleLarge',
    })
    .subscribe();
  }

  onAddKeys() {
    this.alerts
    .open(`Ключи добавлен!`,{
      label: 'Успешно',
      status: 'success',
      icon: 'tuiIconMessageCircleLarge',
    })
    .subscribe();
  }
}
