import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-svg',
  templateUrl: './svg.component.html',
  styleUrls: ['./svg.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SvgComponent {
  @Input() src: any;
}
