import { first } from "rxjs";

export abstract class Footer {
  items: any = [];

  constructor(public changeDetector: any) {}

  set setItems(items: any) {
    items
    .pipe(
      first()
    )
    .subscribe((response: any) => {
      this.items = response;

      this.changeDetector.detectChanges();
    });
  }
}