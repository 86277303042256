import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuComponent } from './menu.component';
import { ActionsComponent } from './widgets/actions/actions.component';
import { TuiDropdownModule, TuiLoaderModule, TuiSvgModule } from '@taiga-ui/core';
import { TuiSidebarModule } from '@taiga-ui/addon-mobile';
import { ActionsDirective } from './widgets/actions/actions.directive';
import { RouterModule } from '@angular/router';
import { TuiActiveZoneModule } from '@taiga-ui/cdk';
import { SvgModule } from 'src/shared/components/svg/svg.module';



@NgModule({
  bootstrap: [
    MenuComponent,
  ],
  declarations: [
    MenuComponent,
    ActionsComponent,
    ActionsDirective,
  ],
  imports: [
    CommonModule,
    TuiSvgModule,
    TuiSidebarModule,
    RouterModule,
    TuiActiveZoneModule,
    TuiDropdownModule,
    SvgModule,
    TuiLoaderModule,
  ],
  exports: [
    MenuComponent,
  ],
})
export class MenuModule { }
